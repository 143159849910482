
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CommonRankingBreakdown from '@/modules/common/modules/ranking/components/ranking-breakdown.common.vue';
import type HotelsService from '@/modules/hotels/hotels.service';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';

@Component({
    components: { CommonRankingBreakdown },
})
export default class ChainRankingBreakdown extends Vue {
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;

    @Prop({
        type: String,
        required: true,
    })
    private providerId!: string;

    @Prop({
        type: Number,
        required: true,
    })
    private currentHotelId!: number;

    @Prop({
        type: String,
        required: true,
    })
    compsetId!: string;

    get hotel() {
        return this.clusterRankingService.getHotelBy({ hotelId: this.currentHotelId });
    }

    get hotels() {
        if (!this.hotel) {
            return null;
        }
        const hotels = this.clusterRankingService.hotels(this.hotel.compsetMain, this.providerId);

        if (!hotels) {
            return null;
        }

        const keys = Object.keys(hotels);

        if (!keys || !keys.length) {
            return null;
        }

        const tableData = keys.map((key: string) => ({
            ...hotels[Number(key)],
            hotelId: Number(key),
            hotelName: this.currentHotelId === Number(key) ? this.hotelsService.hotelNames[key] : hotels[Number(key)].hotelName,
        }));

        tableData.sort((a, b) => b.reviews - a.reviews);
        tableData.sort((a, b) => b.rating - a.rating);

        return tableData;
    }
}
